@use '../../styles/shared/include' as *;

.imageColTextForm {
	display: flex;
	flex-direction: column-reverse;
	height: fit-content;
	@media #{$lgn} {
		flex-direction: row;
	}
	.textSection {
		display: flex;
		justify-content: center;
		padding: rem(48) rem(20);
		color: $deepBlue;
		@media #{$lgn} {
			width: 41.66%;
			padding: rem(112) rem(120);
		}
		width: 100%;
		flex-direction: column;

		.headingTagline {
			display: block;
		}

		.headingDescriptionText {
			font-size: rem(18);
			font-weight: 500;
			line-height: 140%;
			padding-bottom: rem(48);
			color: $deepBlue;
		}

		h2 {
			font-size: rem(40);
			font-weight: 300;
			line-height: 100%;
			margin-bottom: rem(24);
			color: $deepBlue;
			@media #{$lgn} {
				font-size: rem(64);
			}
		}

		form {
			display: flex;
			flex-direction: column;
			label {
				margin-bottom: rem(5);
				font-weight: 500;
				line-height: 125%;
				color: $black;
			}
			span {
				font-size: rem(12);
				color: red;
				height: rem(24);
			}
			select {
				border: rem(1) solid $periwinkleGrey;
				border-radius: rem(3);
				font-size: rem(16);
				font-weight: 500;
				padding: rem(12) rem(24);
				line-height: 125%;
				-webkit-appearance: none;
				background: $dropdown no-repeat calc(100% - rem(24));
			}
			input {
				width: 100%;
				padding: rem(12);
				border: rem(1) solid $periwinkleGrey;
				border-radius: rem(3);
				font-size: rem(16);
				font-weight: 500;
			}
			.buttonContainer {
				width: rem(160);
				padding-top: rem(14);
			}

			.formHeading {
				color: $deepBlue;
				font-size: rem(20);
				font-style: normal;
				font-weight: 500;
				line-height: rem(20);
				margin-bottom: rem(16);
			}
		}
		.formTagline {
			padding-bottom: rem(16);
			font-size: rem(20);
			font-weight: 500;
			line-height: 100%;
		}
	}
	.imageSection {
		height: auto;
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			aspect-ratio: (803/600);
		}

		@media #{$lgn} {
			height: auto;
			width: 58.33%;
		}
	}

	&--imageSmallDesign {
		@media #{$lgn} {
			.textSection {
				width: 58.33%;
				min-height: rem(803);
				padding: rem(112) rem(121) rem(112) rem(115);
			}
			.imageSection {
				width: 41.66%;
			}
		}
	}
}
