@use '../../../styles/shared/include' as *;

.formBottomToast {
  overflow: hidden;

  height: 0;
  max-height: 0;
  min-height: 0;
  opacity: 0;
  
  display: flex;
	flex-direction: row;
  flex-grow: 1;
	color: var(--Light-UI-Text-Contrast, #000);
  
	font-family: "DM Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	padding: 16px;
	align-items: center;
  justify-content: center;
	gap: 10px;
	align-self: stretch;
  text-align: left;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;

  .icon {
    flex-shrink: 0;
    margin-bottom: 2px;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }

	@media #{$md} {
    flex-direction: column;
    text-align: center;
		display: flex;
		flex-direction: row;
	}
	
	@media #{$xl} {
		padding: 20px 40px;
	}
}

.formBottomToast.open {
  height: unset;
  max-height: unset;
  min-height: 64px;
  padding: 8px;
  opacity: 1;
  transition: color 500ms linear;

  @keyframes openToast {
    0% {
      height: 0;
      min-height: 0;
      opacity: 0;
      padding: 0;
    }
    100% {
      height: 70px;
      max-height: 70px;
      min-height: 64px;
      opacity: 1;
      padding: 8px;
    }
  }
  animation: openToast 500ms;
}

.formBottomToast.close {
  height: 0;
  max-height: 0;
  min-height: 0;
  opacity: 0;
  transition: color 500ms linear;

  @keyframes closeToast {
    0% {
      height: 70px;
      max-height: 70px;
      min-height: 64px;
      opacity: 1;
    }
    100% {
      height: 0;
      max-height: 0;
      min-height: 0;
      opacity: 0;
    }
  }
  animation: closeToast 500ms;
}

.formBottomToast {
  border-top: $cream;
	background: $cream;
}

.formBottomToast.success {
  border-top: 1px solid var(--Light-UI-Border-Confirmation, #2A6100);
  background: var(--Light-UI-Surface-Confirmation, #D1FFAD);
}

.formBottomToast.error {
  border-top: 1px solid var(--Light-UI-Border-Error, #B30015);
  background: var(--Light-UI-Surface-Error, #FFADB7);
}