/* components/Header.module.scss */
@use '../../../styles/shared/include' as *;

.menuDesktopContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: rem(35) 0 rem(45) 0;

  h5 {
    color: $white;
    font-size: rem(16);
  }

  ul {
    margin-top: rem(44);
    max-height: 42vh;
    overflow-y: auto;

    @media #{$xl} {
      max-height: 46vh;
    }
  }

  .menuHeading {
    padding-left: rem(32);
  }

  .menuDesktopContent {
    display: flex;
    justify-content: space-between;
    padding: rem(10) rem(3.5) rem(20);

    h3 {
      font-size: rem(32);
      font-weight: 300;
      color: $white;
      width: 100%;

      a {
        position: relative;
        padding-top: rem(1);

        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: rem(1);
          bottom: rem(-2);
          right: 0;
          background-color: $brightYellow;
          transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }

        &:hover {
          color: $brightYellow;
        }

        &:hover::after {
          left: 0;
          right: auto;
          width: 100%;
        }
      }

      button {
        text-align: left;
      }

      visibility: visible;

      .menu-list-desktop {
        background: transparent;
        border: none;
        color: $white;
        font-size: rem(32);
        font-weight: 300;
        padding-inline: 0;
        &:hover {
          border: none;
          color: $brightYellow;
        }
        transform: none;
      }
    }
  }

  .menuBottomContent {
    display: flex;
    width: 100%;
    max-height: 12vh;

    h5 {
      position: relative;
      font-size: rem(16);
      color: $white;
      font-weight: 500;
      margin-right: rem(39);

      &:hover {
        color: $brightYellow;
      }

      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: rem(1);
        bottom: rem(-2);
        right: 0;
        background-color: $white;
        transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }

      &:hover::after {
        background-color: $brightYellow;
        left: 0;
        right: auto;
        width: 100%;
      }
    }
  }

  &:last-child {
    padding-right: rem(100);
  }
}

.megaMenuContainer {
  position: relative;
  z-index: 9;
  height: 100%;
}

.menuDesktopBanner {
  border-top: rem(2) solid $periwinkleGrey;
  height: 85%;
  display: grid;
  grid-template-columns: 25% 45% 30%;
  background-color: $deepBlue;

  @media #{$xxl} {
    grid-template-columns: 25% 46% 29%;
  }
  
  &.containsDualList {
    grid-template-columns: 26% 74%;
    .menuDesktopContentWrapper {
      ul {
        display: flex;
        flex-wrap: wrap;
      }
      li {
        width: 50%;
      }
      h5 {
        padding-left: rem(3.5);
      }
    }
  }
}

.menuDesktopImageContent {
  overflow: hidden;
  padding-bottom: rem(110);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top right;

    @media (min-height: 900px) {
      object-fit: cover;
      object-position: top left;
    }

    @media (min-width: 1400px) {
      object-fit: contain;
      object-position: top right;
    }
  }
}

.megaMenuFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 15%;

  button {
    background: transparent;
    display: flex;
    align-items: center;
    color: $white;
    cursor: pointer;
    padding: rem(0) rem(44);

    svg {
      width: rem(24);
      height: rem(24);
    }

    @media #{$xxl} {
      font-size: rem(16);
    }

    @media #{$xlg} {
      font-size: rem(18);

      svg {
        width: rem(26);
        height: rem(26);
      }
    }
  }
}
