@use '../../styles/shared/include' as *;

.mortgageVerificationContainer {
  width: 100%;
	background: var(--Light-UI-Surface-Primary, #FFF);
	color: var(--Light-UI-Text-Secondary, #4F5E78);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: stretch;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
  min-height: calc(max(80vh - 128px, 160px));
	
  @keyframes fadeZoomIn {
    0% {
      transform: scale(0.75);
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

	h1 {
		text-align: center;
    align-self: stretch;
		color: var(--Light-UI-Text-Primary, #003049);
    font-family: $primary;
    font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	p {
  	font-size: 16px;
    color: var(--Light-UI-Text-Primary, #003049);
    font-family: $primary;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    align-self: stretch;
	}

  em {
    font-style: normal; 
    font-weight: 700;
  }
  
	.header {
		background: var(--Light-UI-Surface-Tertiary, #FFFFE6);
		display: flex;
		min-height: 166px;
		padding: 20px 40px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 20px;
		align-self: stretch;

    p {
      text-align: center;
    }
		
		.list {
			display: flex;
      flex-direction: column;
			align-items: stretch;
      justify-content: flex-start;
			gap: 20px;

			.listItem {
				display: flex;
        flex-direction: row;
        justify-content: flex-start;
				align-items: center;
				gap: 5px;
				list-style: decimal;
				color: var(--Deep-Blue, #003049);
				font-family: "DM Sans", sans-serif;
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
        
				div {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					gap: 10px;
          min-width: 32px;
          min-height: 32px;
					background: var(--Light-UI-Surface-Brand, #F0FF00);
          font-weight: 700;
				}
			}
		}
	}

  .tooltipText {
    cursor: pointer;
    font-weight: 550;
    margin-bottom: -2px;
    border-bottom: 1px dashed;
  }
  
  .tooltipContent {
    max-width: 340px;
    padding: 10px 5px 10px 10px;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid var(--Light-UI-Border-Inverse, #FFF);
    background: var(--Light-UI-Surface-Contrast, #003049);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    background: var(--Light-UI-Surface-Contrast, #003049);
    color: var(--Light-UI-Border-Inverse, #FFF);
  }

  .loader {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 12px;
    height: calc(80vh - 360px);
    min-height: 256px;
    animation: fadeZoomIn 500ms;

    .spinner {
      @keyframes spinner {
        to {
          transform: rotate(360deg);
        }
      }

      animation: spinner 1000ms linear infinite;
    }
  }

  .submitMessage {
    z-index: 101 !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: var(--Light-UI-Text-Primary, #003049);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    width: 100%;
    height: 100vh;
    color: var(--Deep-Blue, #003049);
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
    text-align: center;
  
    h1 {
      color: var(--Deep-Blue, #003049);
      text-align: center;
      font-family: "DM Sans";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 175%;
    }

    animation: fadeZoomIn 500ms ease-in;
  }
  
  @media #{$sml} {
    .loader {
      height: calc(90vh - 360px);
    }

    .submitMessage {
      font-size: 24px;
      h1 {
        font-size: 60px;
      }
    }
  }

  @media screen and (min-width: 874px) {
    .loader {
      height: calc(100vh - 360px);
    }

    .submitMessage {
      font-size: 32px;
      h1 {
        font-size: 80px;
      }
    }
  }

	form {
		display: flex;
		flex-direction: column;
		gap: 40px;
		align-self: center;
		align-items: center;
		justify-content: center;
    width: 100%;
    max-width: 794px;
		padding: 60px 0 40px 0;
		flex: 1 0 0;

    fieldset {
      display: flex;
      flex-direction: column;
      gap: 40px;
      align-self: center;
      align-items: center;
      justify-content: center;
    }
	}

  .preHeader {
		margin: 60px 0 0 0;
		flex-direction: column;
		align-items: center;

    h1 {
      color: var(--Light-UI-Text-Primary, #003049);
      text-align: center;
      font-family: "DM Sans";
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  
	.preSection {
		display: flex;
    width: 100%;
    max-width: 794px;
		flex-direction: column;
		align-items: center;
		gap: 20px;
		padding: 20px 0 0 0;
    animation: fadeZoomIn 500ms;

    .controls {
      justify-content: center;
      align-items: center;
    }
	}

  .preSubmitButton {
    display: flex;
    width: calc(min(100% - 40px, 386px));
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    border: 0;
    background: var(--Light-UI-Surface-Brand, #F0FF00);
    color: var(--Light-UI-Text-Primary, #003049);

    &:hover {
      background: var(--Light-UI-Surface-Brand, #F0FF00);
			color: var(--Light-UI-Text-Primary, #003049);
    }
  }

	.section {
    display: flex;
    width: 100%;
    max-width: 794px;
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
    animation: fadeZoomIn 500ms;

		.controls {
			padding-left: 35px;
		}
	}

	.sectionHeader {
		width: 100%;
		display: flex;
		height: 24px;
		align-items: center;
		align-self: stretch;
		gap: 10px;
		flex: 1 0 0;
		overflow: hidden;

		.icon {
			width: 24px;
			height: 24px;
			color: var(--Light-UI-Text-Primary, #003049);
			align-items: center;
			align-self: stretch;
		}

		h3 {
			display: inline-block;
			color: var(--Deep-Blue, #003049);
      white-space: nowrap;
		}

		.divider {
      width: 100%;
      height: 1px;
      border-color: var(--Light-UI-Border-Tertiary, #BFC6D4);
      border-bottom-width: 1px;
		}
	}

	.controls {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
		align-self: stretch;

		.controlRow {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 20px;
			align-self: stretch;

      .filler {
        width: 100%;
      }
		}

		.fileInputRow {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 20px;
			align-self: stretch;
		}
	}
  
  @media #{$xs} {
    form, .preSection, .section {
      width: calc(100vw - 40px);
    }
  }

  @media #{$nm} {
    .preSection {
      width: 386px;
    }

    .section {
      min-width: 386px;
    }
  }
  
  @media #{$sml} {
    .header {
      .list {
        flex-direction: row;
      }
    }

    .controls {
      .controlRow {
        flex-direction: row;
      }
    }
    
    min-height: calc(90vh - 160px);
  }
  
  @media screen and (min-width: 874px) {
    min-height: calc(100vh - 160px);
  }
}

body.isSubmitted {
  background: var(--Light-UI-Surface-Brand, #F0FF00);
  header {
    border-bottom: none;
    // TODO: fade out animation
    div:first-child {
      background-color: transparent !important;
      border-bottom: none;
      @keyframes headerFadeOut {
        0% {
          background-color: var(--Deep-Blue, #003049);;
          border-bottom: unset;
        }

        100% {
          background-color: transparent !important;
          border-bottom: none;
        }
      }
      animation: headerFadeOut 500ms ease-in-out;
    }

    img {
      opacity: 0;
      // TODO: fade color change animation 
      @keyframes colorChangeLogo {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
      animation: colorChangeLogo 500ms ease-in-out;
    }
  }

  footer {
    opacity: 0;
    height: 0;
    @keyframes headerFadeOut {
      0% {
        background-color: var(--Deep-Blue, #003049);;
        border-bottom: unset;
      }

      100% {
        background-color: transparent !important;
        border-bottom: none;
        height: 0;
      }
    }
    animation: footerFadeOut 500ms ease-in-out;
  }

  div.mortgageVerificationContainer {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  
    form {
      .section {
        max-height: 5vh;
        
        @keyframes shrinkSection {
          0% {
            max-height: 10vh;
          }
  
          100% {
            max-height: 2.5vh;
          }
        }
        animation: shrinkSection 500ms ease-out;
      }
      
    }
  }

}