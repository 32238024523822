/* styles/Button.module.scss */
@use '../../../styles/shared/include' as *;

.button {
	&:not(:last-child) {
		margin-right: rem(24);
	}
	display: inline-block;
	font-size: rem(20);
	font-family: 'DM Sans', sans-serif;
	padding: rem(16);
	font-weight: 500;
	line-height: rem(20);
	border-radius: rem(100);
	color: var(--text-color);
	background-color: var(--bg-color);
	border: rem(1) solid var(--border-color);
	cursor: pointer;
	text-align: center;
	transition: background-color 0.3s, color 0.3s;

	@media #{$md-m} {
		width: 100%;
	}

	svg {
		display: inline-block;
		margin-left: rem(8);
	}

	@media #{$md} {
		width: fit-content;
	}
	:global {
		.svg {
			&.is-icon-right {
				margin-left: 0;
				margin-right: rem(8);
			}
		}
	}

	// TODO(khaney): use palette instead of hardcoding colors
	// Right now bg of transparent causes hover text to disappear
	&:hover {
		background-color: var(--text-color);
		color: var(--bg-color);
		border: rem(1) solid var(--bg-color);

		path {
			fill: var(--bg-color, $deepBlue) !important;
		}
	}

	&--isMenu {
		align-content: center;
		font-size: 1rem;

		@media (orientation: landscape) and (max-height: 420px) {
			margin-right: 0 !important;
		}
	}

	&.narrow {
		padding: rem(8) rem(16);
	}
}
