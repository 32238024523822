@use '../../styles/shared/include' as *;
.single__leader__hero{
  border-bottom: rem(1) solid $periwinkleGrey;
  :global{
    .row{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: row-reverse;
    }
    figure{
      width: 100%;
      @media #{$md}{
        width: calc((600 / 1440) * 100%);
      }
      img{
        width: 100%;
        height: 100%;
       object-fit: cover;
       object-position: top;
      }
    }
    article{
      width: 100%;
      padding: rem(50) rem(20);
      display: flex;
      flex-direction: column;
      gap: rem(16);
      text-align: center;
      @media #{$md}{
        width: calc((840 / 1440) * 100%);
        padding: rem(50) rem(20) rem(50);
        padding-left: rem(60);
        text-align: left;
      }
      @media #{$lg}{
        gap: rem(24);
        padding: rem(10) rem(20) rem(50);
        padding-left: rem(120);
      }
      h4{
        color: $deepBlue;
        font-size: rem(16);
        font-weight: 800;
        line-height: 125%;
        letter-spacing: rem(2.4);
        text-transform: uppercase;
        font-family: $secondary;
      }
      h1{
        color: $deepBlue;
        font-size: rem(42);
        font-weight: 300;
        line-height: 100%;
        @media #{$md}{
          font-size: rem(56);
        }
        @media #{$lg}{
          letter-spacing: rem(-4);
          font-size: rem(72);
        }
      }
      p{
        color: $deepBlue;
        font-size: rem(16);
        font-weight: 500;
        line-height: 140%;
        @media #{$md}{
          font-size: rem(18);
        }
      }
    }
  }
}
