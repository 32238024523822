@use '../../styles/shared/include' as *;

.leaders {
	:global {
		figure {
			width: 100%;
			margin-bottom: rem(24);
			img {
				width: 100%;
				height: 100%;
				object-position: top;
				object-fit: cover;
			}

			&:before {
				background-color: unset !important;
			}
		}
		article {
			h2 {
				color: $deepBlue;
				font-size: rem(24);
				font-weight: 300;
				line-height: 100%;
				margin-bottom: rem(8);
				@media #{$md} {
					font-size: rem(28);
				}
			}
			p {
				color: $deepBlue;
				font-size: rem(15);
				font-weight: 500;
				line-height: 140%;
				margin-bottom: 0;
				@media #{$md} {
					font-size: rem(18);
				}
			}
		}
	}
}
