@use '../../styles/shared/include' as *;

.iconArticleBannerSection {
	:global {
		.container {
			&--max {
				padding: 0;
			}
		}
	}
	.iconArticleBanner {
		display: flex;
		flex-direction: column;

		@media #{$lg} {
			flex-direction: row;
		}

		figure {
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			@media #{$lg} {
				flex-basis: 41.66%;
				width: 41.66%;
			}
		}
		.description {
			background-color: $white;
			color: $deepBlue;
			padding: rem(40);
			@media #{$lg} {
				flex-basis: 58%;
				width: 58%;
				padding: rem(112) rem(120);
			}
		}
		.iconContainer {
			display: flex;
			gap: rem(24);
			margin-bottom: rem(32);
			svg {
				width: rem(40);
				height: rem(40);
				mask {
					display: none;
				}
			}
			path {
				fill: $periwinkleGrey !important;
			}
		}
		article {
			h5 {
				font-size: rem(48);
				font-style: normal;
				font-weight: 300;
				line-height: 100%;
				margin-bottom: rem(24);
			}
			@media #{$xxl} {
				min-width: rem(595);
			}
		}

		&:nth-child(4n + 1) {
			.description {
				background-color: $deepBlue;
				color: $white;
			}
		}

		&:nth-child(4n + 3) {
			.description {
				background-color: $cream;
			}
		}
	}
}
