/* components/Header.module.scss */
@use '../../../styles/shared/include' as *;

.megaMenuContentsContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  background-color: $deepBlue;
  position: absolute;
  width: 100%;
  overflow: hidden;
}

.menuMobileHeading {
  padding: rem(72) rem(20) rem(24);
  color: $white;
  position: relative;

  h5 {
    font-size: rem(24);
    font-weight: 300;
    line-height: 100%;
  }

  .backBtn {
    button {
      cursor: pointer;
      background: transparent;
      color: $white;
      font-size: rem(16);

      &:hover {
        color: $brightYellow;
      }
    }
  }

  &--subheading {
    padding-top: rem(72);
    display: flex;
    flex-direction: column;
    gap: rem(20);
    justify-content: space-between;
  }
}

.subMenuMobileHeading {
  padding: rem(72) rem(20) rem(24);
  color: $white;
  position: relative;

  h5 {
    font-size: rem(24);
    font-weight: 300;
    line-height: 100%;
  }

  .backBtn {
    svg {
      height: rem(16);
      width: rem(16);
    }

    &:hover {
      path {
        stroke: $brightYellow !important;
      }

      button {
        color: $brightYellow;
      }
    }

    button {
      cursor: pointer;
      background: transparent;
      color: $white;
      font-family: $primary;
      font-size: rem(16);
      font-weight: 300;
      line-height: rem(16);
    }

    .backBtnContainer {
      display: flex;
      align-items: center;
      gap: rem(8);
    }
  }

  &--subheading {
    padding-top: rem(36);
    display: flex;
    flex-direction: column;
    gap: rem(20);
    justify-content: space-between;
  }
}

.subMenuMobileContent {
  display: flex;
  justify-content: space-between;
  font-size: rem(18);
  font-weight: 300;
  max-height: rem(68);
  border-bottom: rem(1) solid $periwinkleGrey;

  &:first-child {
    border-top: rem(1) solid $periwinkleGrey;
  }

  svg {
    width: rem(15);
    height: rem(15);
  }

  .menuItemLink {
    width: 100%;

    a {
      width: 100%;
      padding: rem(20);
    }

    button {
      text-align: left;
    }

    &:hover {
      color: $brightYellow !important;
    }
  }

  .menuItemDropdown {
    width: 100%;
    padding: rem(20);
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
      color: $brightYellow;
    }
  }
}

.subMenuList {
  height: calc(100% - 322px);
  overflow-y: auto;
}

.menuMobileContentWrapper {
  height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
  color: $white;

  @media (orientation: landscape) and (max-height: 420px) {
    min-height: 100px;
  }
}

.menu-list-mobile {
  background: transparent;
  border: none;
  color: $white;
  font-size: rem(18);
  padding: 1.25rem;
  transform: none;
  &:hover {
    border: none;
  }
}

.subMenuMobileContentWrapper {
  background-color: $deepBlue;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  height: 45vh;
  pointer-events: none;
  overflow-x: hidden;
  color: $white;
  transform: translateX(100%);
  pointer-events: all;

  .menuActionButtonsContainer {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .subMenuItemFooter {
    width: 100%;
    display: flex;
    font-size: rem(16);
    font-weight: 300;
    color: $white;
    justify-content: space-evenly;

    a {
      &:hover {
        color: $brightYellow;
      }
    }
  }

  &--isActive {
    height: 100%;
    overflow-y: auto;
    pointer-events: all;
    animation: anim 0.3s linear;
    animation-fill-mode: forwards;
  }

  &--isInactive {
    height: 100vh;
    pointer-events: all;
    animation: backanim 0.3s linear;
    animation-fill-mode: backwards;
  }

  @keyframes anim {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes backanim {
    100% {
      transform: translateX(100%);
    }

    0% {
      transform: translateX(0);
    }
  }
}
