@use '../../styles/shared/include' as *;
.leadership__detail{
  padding: rem(50) 0 rem(40);
  @media #{$md}{
    padding: rem(120) 0 rem(95);
  }
  :global{
    .col_content{
      column-count: 1;
      @media #{$lg}{
        column-count: 2;
      }
    }
    h2{
      color: $deepBlue;
      font-size: rem(22);
      line-height: 100%;
      font-weight: 400;
      margin-bottom: rem(16);
      @media #{$md}{
        font-size: rem(28);
        margin-bottom: rem(24);
        letter-spacing: rem(-0.3);
      }
    }
    h3{
      color: $deepBlue;
      font-size: rem(20);
      line-height: 100%;
      font-weight: 400;
      margin-bottom: rem(20);
      @media #{$md}{
        font-size: rem(24);
        margin-bottom: rem(12);
      }
    }
    p{
      color: $black;
      font-size: rem(16);
      line-height: 137.77%;
      font-weight: 400;
      margin-bottom: rem(16);
      @media #{$md}{
        line-height: 177.77%;
        font-size: rem(18);
        margin-bottom: rem(25.6);
        padding-right: rem(60);
      }
      @media #{$lg}{
        padding-right: rem(80);
      }
      @media #{$xxl}{
        padding-right: rem(120);
      }
    }
    ul{
      padding-left: rem(20);
      li{
        color: $black;
        font-size: rem(16);
        line-height: 117.77%;
        font-weight: 400;
        list-style-type: disc;
        @media #{$md}{
          font-size: rem(18);
        }
        &+li{
          margin-top: rem(12);
        }
      }
    }
  }
}