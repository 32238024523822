@use '../../styles/shared/include' as *;

.heroBanner {
	display: flex;
	// font-size: rem(18);
	flex-direction: column;
	@media #{$lg} {
		flex-direction: row;
	}

	.ctaSection button {
		margin: 0 rem(12) rem(12) 0;
	}

	.textContent {
		width: 100%;
		padding: rem(48) rem(20);
		text-align: left;
		align-self: center;
		justify-content: center;
		span {
			font-family: $secondary;
			color: $deepBlue;
			font-size: rem(16);
			font-weight: 800;
			text-transform: uppercase;
			letter-spacing: rem(2.4);
			margin-bottom: rem(16);
			line-height: rem(20);
			display: block;
		}
		h1 {
			font-size: rem(40);
			font-weight: 300;
			line-height: 100%;
			margin-bottom: rem(17);

			@media #{$nm} {
				font-size: rem(40);
			}
			@media #{$lg} {
				min-width: unset;

				font-size: rem(48);
			}
			@media #{$lgn} {
				font-size: rem(60);
			}

			@media #{$xxl} {
				font-size: rem(64);
			}
			@media #{$vl} {
				font-size: rem(90);
				padding-bottom: rem(30);
			}
		}

		p {
			margin-bottom: rem(22);
			a {
				text-decoration: underline;
				&:hover {
					color: $hoverBlue;
				}
			}
			@media #{$lg} {
				max-width: 100%;
			}
		}
		ul {
			li {
				p {
					padding-top: 0 !important;
					margin-bottom: 0;
				}
			}
		}

		@media #{$lg} {
			width: 58.33%;
			padding: rem(30) rem(120);
		}

		@media #{$vl} {
			p {
				font-size: rem(25);
			}
		}

		&--design-2 {
			@media #{$md} {
				h1 {
					font-size: rem(48);
				}
			}
		}
	}

	.imageContent {
		width: 100%;
		height: auto;
		img {
			display: block;

			object-position: center;
			width: 100%;
			height: 50%;
		}
		@media #{$nm} {
			img {
				height: 90%;
			}
		}
		@media #{$lg} {
			img {
				object-fit: cover;
				height: 100%;
			}
			h1 {
				min-width: rem(600);
			}

			width: 42%;
		}
	}
	&--imagePosition {
		@media #{$lg} {
			flex-direction: row-reverse;
		}
	}
	&--equalWidth {
		.imageContent {
			@media #{$lg} {
				width: 50%;
			}
		}

		.textContent {
			@media #{$lg} {
				width: 50%;
			}
			p {
				margin-bottom: rem(48);
			}
		}
	}

	&--DeepBlue {
		background: $deepBlue;
		color: $white;
		.textContent {
			span {
				color: $brightYellow;
			}
			p {
				a {
					text-decoration: underline;
					&:hover {
						color: $brightYellow;
					}
				}
			}
		}
	}
	&--firstComponent {
		min-height: calc(100vh - (257px + var(--announcement-banner-height)));

		@media #{$lgn} {
			min-height: calc(100vh - (265px + var(--announcement-banner-height)));
		}
		@media screen and (min-width: 1208px) and (min-height: 600px) and (max-height: 800px) {
			min-height: calc(100vh - (80px + var(--announcement-banner-height)));
		}
	}
}
