/* styles/Button.module.scss */
@use '../../../styles/shared/include' as *;

.formButtonGrow {
  display: flex;
  width: calc(min(100% - 40px, 386px));
  height: 2.875rem;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 32px;
  background: transparent;
  text-align: center;
  font-family: $primary;
  overflow: hidden;

  .loader{
    display: inline-block;
    margin-bottom: 2px;
    margin-right: 5px;

    @keyframes loader {
      0% {
        rotate: 0deg;
      }
      12.5% {
        rotate: 45deg;
      }
      25% {
        rotate: 90deg;

      }
      37.5% {
        rotate: 135deg;
      }
      50% {
        rotate: 180deg;
      }
      62.5% {
        rotate: 225deg;
      }
      75% {
        rotate: 270deg;
      }
      87.5% {
        rotate: 315deg;
      }
      100% {
        rotate: 360deg;
      }
    }
    animation: loader 1000ms steps(1) infinite;
  }

  button.formButtonGrowElement {
    cursor: pointer;
    background: var(--Dark-UI-Surface-Brand, #F0FF00);
    color: var(--text-color);
    font-size: 20px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0;
    margin: 0;
    padding: 10px 24px;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 1rem;
  }

  div.formButtonGrowContent {
    background: var(--Dark-UI-Surface-Brand, #F0FF00);
    position: fixed;
    display: none;
  }
}

.formButtonGrow.success {
  width: 100%;

  @keyframes growCard {
    0% {
      width: calc(min(100% - 40px, 386px));
      height: 3rem;

      position: absolute;
      left: calc(50% - min(50% - 20px, 193px));
      right: calc(50% - min(50% - 20px, 193px));
      top: 82vh;
      bottom: calc(18vh - 3rem);
      
      border-radius: 128px;
    }

    60% {
      border-radius: 196px;
    }

    100% {
      width: 100%;
      height: 100vh;

      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }
  animation: growCard 500ms ease-in;
  
  div.formButtonGrowContent {
    z-index: 98;
    display: block;
    position: absolute;
    padding: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: unset;
    max-height: unset;
    width: 100vw;
    height: 100vh;
    border: none;
    border-radius: 0;
    background: var(--Dark-UI-Surface-Brand, #F0FF00);
  }

  button.formButtonGrowElement {
    display: none;
    height: 0;
    width: 0;
  }
}

.formButtonGrow.fail {
  opacity: 0.4;
  
  button.formButtonGrowElement {
    cursor: unset;
  }
}

