@use '../../styles/shared/include' as *;
.leadership__hero{
  padding: rem(50) 0 rem(30);
  overflow: hidden;
  @media #{$md}{
    padding: rem(112) 0 rem(64);
  }
  :global{
    .container--max{
      position: relative;
    }
    .hero__logo-layer{
      position: absolute;
      right: rem(-2);
      top: calc(50% - 24px);
      transform: translateY(-50%);
      width: rem(100);
      display: none;
      @media #{$md}{
        display: block;
      }
      @media #{$lg}{
        width: rem(141);
        // height: rem(286);
      }
      img{
        width: 100%;
        height: auto;
      }
    }
    .hero__content{
      max-width: rem(620);
      @media #{$lg}{
        max-width: rem(720);
      }
      h4{
        color: $deepBlue;
        font-size: rem(16);
        line-height: 125%;
        font-weight: 800;
        letter-spacing: rem(2);
        text-transform: uppercase;
        font-family: $secondary;
        margin-bottom: rem(20);
        @media #{$md}{
          letter-spacing: rem(2.4);
          margin-bottom: rem(24);
        }
      }
      h1{
        color: $deepBlue;
        font-size: rem(48);
        line-height: 100%;
        font-weight: 300;
        margin-bottom: rem(20);
        @media #{$md}{
          font-size: rem(72);
          margin-bottom: rem(24);
          letter-spacing: rem(-4);
        }
      }
      p{
        color: $deepBlue;
        font-size: rem(16);
        line-height: 140%;
        font-weight: 500;
        @media #{$md}{
          letter-spacing: rem(0.3);
          font-size: rem(18);
        }
        &+p{
          margin-bottom: rem(25.2);
        }
      }
    }
  }
}