@use '../../styles/shared/include' as *;

.leaders__listing {
	:global {
		ul {
			display: flex;
			flex-wrap: wrap;
			border-top: rem(1) solid $periwinkleGrey;
			li {
				padding: rem(40) rem(20);
				width: calc(100% / 1);
				border-right: rem(1) solid $periwinkleGrey;
				border-bottom: rem(1) solid $periwinkleGrey;
				@media #{$sml-m} {
					border-right: 0;
				}
				@media #{$sml} {
					padding: rem(20);
					width: calc(100% / 2);
				}
				@media #{$md} {
					padding: rem(30);
					width: calc(100% / 2);
				}
				@media screen and (min-width: 640px) and (max-width: 1023px) {
					&:nth-child(2n + 0) {
						border-right: 0;
					}
				}
				@media #{$lg} {
					padding: rem(40);
					width: calc(100% / 3);
					&:nth-child(3n + 0) {
						border-right: 0;
					}
				}
				a {
					display: block;
				}
			}
		}
		hr {
			border-bottom: rem(1) solid $periwinkleGrey;
			margin: -1px 0 0;
		}
	}
}
